import { CSSProperties } from 'react';
import './Button.css';

interface ButtonProps {
  text: string;
  onClick: (e: any) => any;
  primary?: boolean;
  style?: CSSProperties;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick: handleClick,
  primary,
  style,
}) => {
  const onClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    return handleClick(e);
  };

  console.log(primary);

  const className = `button ${primary === true ? 'button-primary' : ''}`;
  console.log(className);
  return (
    <button type="button" className={className} onClick={onClick} style={style}>
      {text}
    </button>
  );
};

export default Button;
