import "./Modal.css";

import ReactDOM from "react-dom";
import { useEffect } from "react";
import AddRoomModal from "./add-room/AddRoomModal";
import AddBetModal from "./add-bet/AddBetModal";

interface ModalProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  type: string;
  data?: any;
}

interface ModalComponents {
  [key: string]: any;
}

export enum ModalTypes {
  ADD_ROOM = "add-room",
  ADD_BET = "add-bet",
}

const MODAL_COMPONENTS: ModalComponents = {
  [ModalTypes.ADD_BET]: AddBetModal,
  [ModalTypes.ADD_ROOM]: AddRoomModal,
};

const Modal: React.FC<ModalProps> = ({ setOpened, opened, type, data }) => {
  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setOpened(false);
    }
  };

  const handleClickOutside = (e: any) => {
    if (e.target.classList.contains("modal-overlay")) {
      setOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  if (!opened) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[type];

  if (!SpecificModal) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={handleClickOutside}>
      <div className="modal">
        <SpecificModal data={data} onClose={() => setOpened(false)} />
      </div>
    </div>,
    document.querySelector("div#modal-root")!
  );
};

export default Modal;
