import './BetListHeader.css';

import { CheckCircle } from 'react-feather';

interface BetListHeaderProps {
  text: string;
  color: string;
  tries: number;
}

const BetListHeader: React.FC<BetListHeaderProps> = ({
  text,
  color,
  tries,
}) => {
  return (
    <div
      className="bet-list-header"
      style={{
        color: color,
      }}
    >
      <CheckCircle size={36} />
      {text}
      <div className="bet-list-header-tries-wrapper">
        <span
          style={{
            color: 'black',
          }}
          className="bet-list-header-tries"
        >
          Število poskusov:
        </span>{' '}
        <span>
          <b>{tries}</b>
        </span>
      </div>
    </div>
  );
};

export default BetListHeader;
