import { useState } from "react";
import { PlusCircle, X } from "react-feather";
import betService from "../../../services/betService";
import Button from "../../common/button/Button";
import Divider from "../../common/divider/Divider";
import InputField from "../../common/input/InputField";
import "./AddBetModal.css";

interface IModalData {
  roomId: number;
}
interface AddBetModalProps {
  onClose: () => void;
  data: IModalData;
}

const AddBetModal: React.FC<AddBetModalProps> = ({ onClose, data }) => {
  const [amountToBet, setAmountToBet] = useState("");
  const [lowBoundary, setLowBoundary] = useState("");
  const [highBoundary, setHighBoundary] = useState("");
  const [error, setError] = useState("");

  const onClick = async () => {
    const response = await betService.create({
      amountToBet: parseInt(amountToBet, 10),
      highBoundary: parseInt(highBoundary, 10),
      lowBoundary: parseInt(lowBoundary, 10),
      roomId: data.roomId,
    });

    if (response.status === 201) {
      return onClose();
    } else {
      const respData = await response.json();
      setError(respData.errors[0].message);
    }
  };

  console.log("SELECTED ROOM ID: ", data);

  return (
    <div className="add-room-modal">
      <div className="add-room-modal-header">
        <div className="add-room-modal-title">
          <PlusCircle size={36} />
          Ustvari stavo
        </div>
        <div className="add-room-modal-close" onClick={onClose}>
          <X size={32} />
        </div>
      </div>
      <Divider />
      <div>
        <InputField
          placeholder="Spodnja meja"
          type="number"
          setValue={setLowBoundary}
          value={lowBoundary}
        />
        <InputField
          placeholder="Zgornja meja"
          type="number"
          setValue={setHighBoundary}
          value={highBoundary}
        />
        <InputField
          placeholder="Stava"
          type="number"
          setValue={setAmountToBet}
          value={amountToBet}
        />
      </div>
      {error && (
        <div className="error">
          <span>{error}</span>
        </div>
      )}

      <div className="buttons">
        <Button text={"Zapri"} onClick={onClose}></Button>
        <Button primary text={"Dodaj"} onClick={onClick} />
      </div>
    </div>
  );
};

export default AddBetModal;
