import { API_URI } from "../constants";

interface IBetAttrs {
  roomId: number;
  amountToBet: number;
  highBoundary: number;
  lowBoundary: number;
}

class BetService {
  async findByRoomId(roomId: number) {
    return fetch(`${API_URI}/bets/${roomId}`, {
      credentials: "include",
    })
      .then((data) => data.json())
      .then((data) => data.data);
  }

  async create(attrs: IBetAttrs) {
    return fetch(`${API_URI}/bets`, {
      method: "POST",
      body: JSON.stringify(attrs),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new BetService();
