import "./Bets.css";

import BetList from "./BetList";
import BetListHeader from "./BetListHeader";
import Divider from "../common/divider/Divider";
import { useEffect, useState } from "react";
import betService from "../../services/betService";
import { IRoom } from "../../App";
import { HashLoader } from "react-spinners";
import { Meh, Plus, Award } from "react-feather";
import { useCallback } from "react";
import registerListener from "../../helpers/sse";

interface BetsProps {
  selectedRoom?: IRoom;
  onBetAddClick: () => void;
  updatePointsOnNewBet: (bet: Bet) => void;
}

export interface Bet {
  id: number;
  roomId: number;
  amountToBet: number;
  lowBoundary: number;
  highBoundary: number;
  userId: number;
  username?: string;
  createdAt: string;
}

const Bets: React.FC<BetsProps> = ({
  selectedRoom,
  onBetAddClick,
  updatePointsOnNewBet,
}) => {
  const [bets, setBets] = useState<Bet[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const onNewBet = useCallback(
    (data: { bet: Bet }) => {
      if (data.bet.roomId === selectedRoom?.id) {
        setBets((oldArray) => [...oldArray, data.bet]);
      }

      updatePointsOnNewBet(data.bet);
    },
    [selectedRoom]
  );

  useEffect(() => {
    registerListener("room-bet-added", onNewBet);

    return function cleanup() {};
  }, [onNewBet]);

  useEffect(() => {
    if (!selectedRoom) return;
    setLoading(true);

    betService.findByRoomId(selectedRoom.id).then((data) => {
      setLoading(false);
      setBets(data);
    });
  }, [selectedRoom]);

  if (loading) {
    return (
      <div className="bets">
        <BetListHeader
          text="Aktivne stave"
          color="#4957fa"
          tries={selectedRoom ? selectedRoom.deathCount : 0}
        ></BetListHeader>
        <div className="bets-loading-wrapper">
          <HashLoader color={"#4957fa"} loading={loading} size={150} />
        </div>
        <div className="room-add-new" onClick={onBetAddClick}>
          <Plus size={32} />
          Dodaj svojo stavo
        </div>
      </div>
    );
  }

  if (!selectedRoom) {
    return null;
  }

  if (bets.length === 0 && selectedRoom) {
    return (
      <div className="bets">
        <BetListHeader
          text="Aktivne stave"
          color="#4957fa"
          tries={selectedRoom.deathCount}
        ></BetListHeader>
        <div className="no-content">
          <Meh size={100} />
          <span>Ni aktivnih stav!</span>
        </div>
        <div className="room-add-new" onClick={onBetAddClick}>
          <Plus size={32} />
          Dodaj svojo stavo
        </div>
      </div>
    );
  }

  const hasWinner = selectedRoom.winnerId !== null;

  return (
    <div className="bets">
      <BetListHeader
        text="Aktivne stave"
        color="#4957fa"
        tries={selectedRoom.deathCount}
      ></BetListHeader>

      <BetList
        inactive={false}
        bets={bets.filter((bet) => bet.highBoundary >= selectedRoom.deathCount)}
      ></BetList>
      <Divider />
      <BetList
        inactive={true}
        bets={bets.filter((bet) => bet.highBoundary < selectedRoom.deathCount)}
      ></BetList>
      {!hasWinner && (
        <div className="room-add-new" onClick={onBetAddClick}>
          <Plus size={32} />
          Dodaj svojo stavo
        </div>
      )}

      {hasWinner && (
        <div className="winner room-add-new ">
          <Award size={32} />
          <span>
            Zmagovalec je <b>{selectedRoom.winnerUsername}</b>
          </span>
          {/* <span>Nagrada: 1900 točk</span> */}
        </div>
      )}
    </div>
  );
};

export default Bets;
