import { API_URI } from "../constants";

const evSource = new EventSource(`${API_URI}/events`);

const listeners: {
  [key: string]: Function;
} = {};

evSource.addEventListener("open", () => {
  console.log("Successfully subscribed to event source.");
});

evSource.addEventListener("message", async (ev) => {
  const parsedData = JSON.parse(ev.data);

  console.log(parsedData);

  if (!parsedData.event) {
    return null;
  }

  listeners[parsedData.event](parsedData);
});

const registerListener = (name: string, listener: Function) => {
  listeners[name] = listener;
};

export default registerListener;
