import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import "./App.css";
import Bets, { Bet } from "./components/bets/Bets";
import Modal, { ModalTypes } from "./components/modal/Modal";
import Rooms from "./components/rooms/Rooms";
import { API_URI, DISCORD_OAUTH_URI } from "./constants";
import registerListener from "./helpers/sse";
import roomService from "./services/roomService";

export interface IUserData {
  points: number;
  username: string;
}

export interface IRoom {
  id: number;
  deathCount: number;
  creatorId: number;
  createdAt: string;
  creatorUsername: string;
  deleted: number;
  description: string;
  winnerId: number | null;
  winnerUsername: string | null;
}

const App: React.FC = () => {
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<IRoom>();
  const [modalOpened, setModalOpened] = useState(false);
  const [modalType, setModalType] = useState("");
  const [userData, setUserData] = useState<IUserData>();
  const [activeRoomId, setActiveRoomId] = useState<null | number>(null);

  useEffect(() => {
    fetch(`${API_URI}/authenticate`, {
      credentials: "include",
    }).then((data) => {
      if (data.status === 401) {
        window.location.href = DISCORD_OAUTH_URI;
        return null;
      }

      data.json().then((userData) => setUserData(userData));

      roomService.findAll().then((data) => {
        setRooms(data.rooms);
        setSelectedRoom(data.rooms[0]);
        setActiveRoomId(data.activeRoomId);
      });
    });
  }, []);

  const onRoomSetActive = useCallback((data: any) => {
    setActiveRoomId(data.roomId);
  }, []);

  const onRoomUpdated = useCallback(
    (data) => {
      if (!selectedRoom) return;

      const newRooms = [...rooms];
      const idx = newRooms.findIndex((room) => room.id === data.room.id);
      newRooms[idx].deathCount = data.room.deathCount;

      setRooms(newRooms);

      if (data.room.id === selectedRoom.id) {
        setSelectedRoom(newRooms[idx]);
      }
    },
    [selectedRoom, rooms]
  );

  const onRoomCreated = useCallback((data) => {
    setRooms((oldArray) => [...oldArray, data.room]);
  }, []);

  // TODO: convert to memo func (add deps)

  const onRoomClosed = useCallback(
    (data: any) => {
      const updatedRooms = rooms.map((room) => {
        if (room.id === data.roomId) {
          return {
            ...room,
            winnerId: data.winner.id,
            winnerUsername: data.winner.discordUsername,
          };
        }

        return room;
      });

      setRooms(updatedRooms);
    },
    [rooms]
  );

  useEffect(() => {
    registerListener("room-updated", onRoomUpdated);
    registerListener("room-created", onRoomCreated);
    registerListener("room-closed", onRoomClosed);
    registerListener("room-activated", onRoomSetActive);

    return function cleanUp() {};
  }, [onRoomCreated, onRoomUpdated, onRoomClosed, onRoomSetActive]);

  const onRoomClick = async (roomId: number) => {
    let room = rooms.find((room) => room.id === roomId);
    setSelectedRoom(room);
  };

  const updatePointsOnNewBet = (bet: Bet) => {
    if (bet.username === userData?.username) {
      setUserData({
        points: userData!.points - bet.amountToBet,
        username: userData!.username,
      });
    }
  };

  const onAddRoomClick = () => {
    setModalOpened(true);
    setModalType(ModalTypes.ADD_ROOM);
  };

  const onBetAddClick = () => {
    setModalOpened(true);
    setModalType(ModalTypes.ADD_BET);
  };

  return (
    <>
      <div className="main">
        <Rooms
          userData={userData}
          activeRoomId={activeRoomId}
          rooms={rooms}
          selectedRoom={selectedRoom}
          onRoomClick={onRoomClick}
          onRoomAddClick={onAddRoomClick}
        />
        <Bets
          updatePointsOnNewBet={updatePointsOnNewBet}
          selectedRoom={selectedRoom}
          onBetAddClick={onBetAddClick}
        />
      </div>
      <Modal
        data={{
          roomId: selectedRoom?.id,
        }}
        opened={modalOpened}
        setOpened={setModalOpened}
        type={modalType}
      />
    </>
  );
};

export default App;
