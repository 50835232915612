import "./BetList.css";

import { Users, ChevronUp, ChevronDown, DollarSign, Meh } from "react-feather";
import { Bet } from "./Bets";

interface BetListProps {
  bets: Bet[];
  inactive: boolean;
}

const BetList: React.FC<BetListProps> = ({ bets, inactive }) => {
  if (bets.length === 0) {
    return (
      <div
        style={{
          marginTop: inactive === false ? "10px" : "",
        }}
        className="bet-list"
      >
        <div
          style={{
            justifyContent: "center",
          }}
          className={`bet-list-item ${inactive ? "inactive" : ""}`}
        >
          {inactive === false
            ? "Ta soba nima aktivnih stav. Slabo vam gre."
            : "Vse vaše stave so aktivne. Dobr za vs :)"}
        </div>
      </div>
    );
  }

  const elements = bets.map((bet) => {
    return (
      <div
        className={`bet-list-item ${inactive ? "inactive" : ""}`}
        key={bet.id}
      >
        <div className="bet-list-item-user">
          <Users size={32} />
          <span>{bet.username}</span>
        </div>
        <div className="bet-list-item-created-at">
          {new Date(bet.createdAt).toLocaleDateString("sl", {})}
        </div>
        <div className="bet-list-item-lower-boundary">
          <ChevronUp size={28} />
          <span>{bet.highBoundary} poskus</span>
        </div>
        <div className="bet-list-item-higher-boundary">
          <ChevronDown size={28} />
          <span>{bet.lowBoundary} poskus</span>
        </div>
        <div className="bet-list-item-amount">
          <DollarSign size={28} />
          <span>{bet.amountToBet} točk</span>
        </div>
      </div>
    );
  });

  return (
    <div
      style={{
        marginTop: inactive === false ? "10px" : "",
      }}
      className="bet-list"
    >
      {elements}
    </div>
  );
};

export default BetList;
