import "./RoomList.css";
import {
  Box,
  CheckCircle,
  Pause,
  PauseCircle,
  PlayCircle,
  Repeat,
  Wifi,
} from "react-feather";
import { IRoom } from "../../App";
import { useCallback } from "react";

interface RoomListProps {
  rooms: IRoom[];
  onRoomClick: (roomId: number) => void;
  selectedRoom?: IRoom;
  activeRoomId: null | number;
}

const RoomList: React.FC<RoomListProps> = ({
  rooms,
  onRoomClick,
  selectedRoom,
  activeRoomId,
}) => {
  const elements = rooms.map((room) => {
    const isCompleted = room.winnerId !== null;
    const isSelected = room.id === selectedRoom?.id;
    const isActive = room.id === activeRoomId;

    return (
      <div
        style={{
          background: isSelected ? "#4958fa34" : undefined,
        }}
        className="room-list-item"
        key={room.id}
        onClick={() => onRoomClick(room.id)}
      >
        <div className="room-list-item-icon">
          <Box size={32} />
        </div>
        <div className="room-list-item-description">{room.description}</div>
        <div className="room-list-item-live-indicator">
          <Wifi size={24} />
        </div>
        <div className="room-list-item-creator">{room.creatorUsername}</div>
        <div className="room-list-item-deaths">
          <span className="room-list-item-deaths-count">{room.deathCount}</span>
          <Repeat size={24} />
        </div>
        <div className="room-list-item-active">
          {isCompleted && <span>Zaključeno</span>}
          {isCompleted && <CheckCircle />}
          {!isCompleted && isActive && <span>Aktivno</span>}
          {!isCompleted && isActive && <PlayCircle />}
          {!isCompleted && !isActive && <span>Neaktivno</span>}
          {!isCompleted && !isActive && <PauseCircle></PauseCircle>}
        </div>
      </div>
    );
  });

  return <div className="room-list">{elements}</div>;
};

export default RoomList;
