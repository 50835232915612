import { Plus } from "react-feather";
import RoomList from "./RoomList";
import RoomListHeader from "./RoomListHeader";
import "./Rooms.css";
import { IRoom, IUserData } from "../../App";

interface RoomsProps {
  rooms: any[];
  userData?: IUserData;
  onRoomClick: (roomId: number) => void;
  onRoomAddClick: () => void;
  selectedRoom?: IRoom;
  activeRoomId: null | number;
}

const Rooms: React.FC<RoomsProps> = ({
  rooms,
  selectedRoom,
  onRoomClick,
  onRoomAddClick,
  userData,
  activeRoomId,
}) => {
  return (
    <div className="rooms">
      <RoomListHeader userData={userData} />
      <RoomList
        activeRoomId={activeRoomId}
        selectedRoom={selectedRoom}
        rooms={rooms}
        onRoomClick={onRoomClick}
      />
      <div className="room-add-new" onClick={onRoomAddClick}>
        <Plus size={32} />
        Dodaj novo sobo
      </div>
    </div>
  );
};

export default Rooms;
