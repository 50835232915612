let API_URI = "https://betting-arena.m4ckon.com/api";
let DISCORD_OAUTH_URI =
  "https://discord.com/api/oauth2/authorize?client_id=925472917184188436&redirect_uri=https%3A%2F%2Fbetting-arena.m4ckon.com%2Fapi%2Foauth&response_type=code&scope=identify%20email%20connections";

if (process.env.NODE_ENV === "development") {
  API_URI = "http://localhost:5433/api";
  DISCORD_OAUTH_URI =
    "https://discord.com/api/oauth2/authorize?client_id=925472917184188436&redirect_uri=http%3A%2F%2Flocalhost%3A5433%2Fapi%2Foauth&response_type=code&scope=identify%20email%20connections";
}

export { API_URI, DISCORD_OAUTH_URI };
