import { DollarSign, Globe, User } from "react-feather";
import { IUserData } from "../../App";

import "./RoomListHeader.css";

interface RoomListHeaderProps {
  userData?: IUserData;
}

const RoomListHeader: React.FC<RoomListHeaderProps> = ({ userData }) => {
  return (
    <>
      <div className="room-list-header">
        <Globe size={36} />
        <div className="header-title">Betting site</div>
        <div className="header-points">
          <DollarSign size={28} />
          <span>
            Število točk: <b>{userData?.points}</b>
          </span>
        </div>
        {/* <div className="header-points">
          <Repeat size={28} />
          1200
        </div> */}
        <div className="header-username-container">
          <User size={32} />
          <div className="header-username">{userData?.username}</div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default RoomListHeader;
