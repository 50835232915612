import { ChangeEvent } from "react";
import "./InputField.css";

interface InputFieldProps {
  placeholder?: string;
  value: string;
  type: string;
  setValue: (value: string) => any;
}

const InputField: React.FC<InputFieldProps> = ({
  placeholder,
  value,
  setValue,
  type,
}) => {
  return (
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className="input"
      type={type}
      placeholder={placeholder}
    ></input>
  );
};

export default InputField;
