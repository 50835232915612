import { API_URI } from "../constants";

class RoomService {
  async findAll() {
    return fetch(`${API_URI}/rooms`, { credentials: "include" })
      .then((data) => data.json())
      .then((data) => data);
  }

  async findById(id: number) {
    return fetch(`${API_URI}/rooms/${id}`, { credentials: "include" }).then(
      (data) => data.json()
    );
  }

  async create(description: string) {
    return fetch(`${API_URI}/rooms`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description,
      }),
    }).then((data) => data.json());
  }
}

export default new RoomService();
