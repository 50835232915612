import { useState } from "react";
import { PlusCircle, X } from "react-feather";
import roomService from "../../../services/roomService";
import Button from "../../common/button/Button";
import Divider from "../../common/divider/Divider";
import InputField from "../../common/input/InputField";
import "./AddRoomModal.css";

interface AddRoomModalProps {
  onClose: () => void;
}

const AddRoomModal: React.FC<AddRoomModalProps> = ({ onClose }) => {
  const [description, setDescription] = useState("");

  const onClick = async () => {
    await roomService.create(description);
    onClose();
  };

  return (
    <div className="add-room-modal">
      <div className="add-room-modal-header">
        <div className="add-room-modal-title">
          <PlusCircle size={36} />
          Ustvari novo sobo
        </div>
        <div className="add-room-modal-close" onClick={onClose}>
          <X size={32} />
        </div>
      </div>
      <Divider />
      <div>
        <InputField
          type="text"
          placeholder="Opis sobe..."
          setValue={setDescription}
          value={description}
        />
      </div>

      <div className="buttons">
        <Button text={"Zapri"} onClick={onClose}></Button>
        <Button primary text={"Dodaj"} onClick={onClick} />
      </div>
    </div>
  );
};

export default AddRoomModal;
